<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-bills'"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
          <v-col cols="14" sm="8" md="10">
            <v-row dense justify="end" class="mb-5">
              <v-btn color="secondary" dark @click="paymentView = true">
                <v-icon color="black"> mdi-cash-refund </v-icon>
                <v-card-text class="black--text font-weight-bold">
                  Add Refund Payment
                </v-card-text>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mt-n4">
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <material-card icon="mdi-account-cash" icon-small color="secondary">
              <v-card-title class="mt-n8">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :search="search"
                :headers="headers"
                :items="items"
                :items-per-page="5"
                hide-default-footer
              >
                <template v-slot:[`item.quantity`]="{ item }">
                  {{
                    item.unitQty
                      ? item.quantity / item.unitQty + item.unitName
                      : item.quantity
                  }}
                </template>
                <template v-slot:[`item.receivedDate`]="{ item }">
                  {{ item.receivedDate | localDate }}
                </template>
                <template v-slot:[`item.refundAmount`]="{ item }">
                  {{ currencyFormat(item.refundAmount) }}
                </template>

                <template v-slot:[`item.delete`]="{ item }">
                  <div @click="deleteInvoice(item)">
                    <v-icon color="red"> mdi-trash-can-outline </v-icon>
                  </div>
                </template>
              </v-data-table>
            </material-card>
            <v-row class="mt-2" justify="center">
              <v-col cols="8" sm="8" md="8">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  @click="changePage"
                  circle
                  color="black"
                ></v-pagination>
              </v-col>
            </v-row> </v-col
        ></v-row>
      </v-container>
      <v-container class="mt-n12"
        ><v-col cols="14" sm="8" md="4" class="float-right">
          <v-row dense class="mb-5 float-right">
            <v-btn color="secondary" @click="viewTrashed()">
              <v-icon color="black"> mdi-delete-restore </v-icon>
            </v-btn>
          </v-row></v-col
        ></v-container
      >
    </v-card-text>

    <v-dialog v-model="paymentView" max-width="500px" origin="top right">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="paymentView = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline"> Refund Payment</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="billPaymentForm">
              <v-row>
                <v-col cols="12" class="mb-n8">
                  <v-text-field
                    v-model="invoiceNo"
                    label="Invoice no"
                    outlined
                  />
                </v-col>
                <v-col cols="12" class="mb-n8">
                  <v-autocomplete
                    v-model="billPayment.billProductId"
                    label="Product"
                    :items="billProducts"
                    item-text="productDescription"
                    item-value="billProductId"
                    @change="onProductSelect"
                    outlined
                  />
                </v-col>
                <v-col cols="12" class="mb-n8">
                  <v-text-field
                    v-model="billPayment.quantity"
                    label="Quantity"
                    outlined
                  />
                </v-col>
                <v-col cols="12" class="mb-n8">
                  <v-text-field
                    v-model="billPayment.refundAmount"
                    label="Amount"
                    outlined
                  />
                </v-col>
                <v-col cols="12" class="mb-n8">
                  <v-text-field
                    v-model="billPayment.reason"
                    label="Reason"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="paymentView = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTrash" max-width="1200">
      <v-card class="pa-10">
        <v-row style="float: right">
          <div>
            <v-icon color="closeButton" @click="dialogTrash = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>

        <v-data-table
          :headers="headers"
          :items="archiveItems"
          :items-per-page="5"
          :search="search"
          hide-default-footer
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | localDate }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ currencyFormat(item.amount) }}
          </template>

          <template v-slot:[`item.restore`]="{ item }">
            <v-icon color="secondary" @click="restoreItem(item)">
              mdi-restore
            </v-icon>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-icon color="#FF0000" @click="permanentDelete(item)">
              mdi-trash-can-outline
            </v-icon>
          </template>
        </v-data-table>

        <v-row class="mt-5" justify="center">
          <v-col cols="8" sm="8" md="8">
            <v-pagination
              v-model="pageArchive"
              :length="pageCountArchive"
              @click="changePageArchive"
              circle
              color="black"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRestore" max-width="500px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogRestore = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title class="headline">
          Are you sure you want to restore
          {{ editedItem.reason }} ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogRestore = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="restoreItemConfm">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogDelete = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title class="headline">
          Are you sure you want to delete {{ editedItem.reason }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogDelete = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPermanentDelete" max-width="500px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogPermanentDelete = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title class="headline">
          Are you sure you want to delete
          {{ editedItem.reason }} permanently?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialogPermanentDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var moment = require("moment");

export default {
  components: { Loading },
  data() {
    return {
      datePickModal: false,
      datePicked: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      invoiceNo: "",
      invoiceSearchTimeout: null,
      billProducts: [],
      items: [],
      archiveItems: [],
      page: 1,
      pageArchive: 1,
      pageCount: null,
      pageCountArchive: null,
      start: 0,
      end: 7,
      dataFiltered: [],

      headers: [
        { text: "Barcode", value: "barCode" },
        { text: "Name", value: "productDescription" },
        { text: "Qty", value: "quantity" },
        { text: "Reason", value: "reason" },
        { text: "Amount", value: "refundAmount" },
        { text: "Received by", value: "receivedBy" },
        { text: "Received at", value: "receivedDate" },
        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],
      paymentView: false,
      billPayment: {
        quantity: "",
        reason: "",
        refundAmount: "",
        billProductId: 0,
      },

      billPaymentDefault: {
        quantity: "",
        reason: "",
        refundAmount: "",
        billProductId: 0,
      },

      editedItem: "",
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dialogTrash: false,

      pagination: { pageNumber: 1, pageSize: 5 },
      paginationArchive: { pageNumber: 1, pageSize: 5 },
    };
  },
  created() {
    this.getDataFromApi();
  },
  watch: {
    invoiceNo(val) {
      this.billPayment.billProductId = 0;
      this.billProducts = [];
      clearTimeout(this.invoiceSearchTimeout);
      this.invoiceSearchTimeout = setTimeout(() => {
        this.getBillProducts(val);
      }, 1500);
    },

    paymentView(val) {
      val || this.setDefault();
    },
    page: function () {
      this.changePage();
    },
    pageArchive: function () {
      this.changePageArchive();
    },
  },
  filters: {
    // moment: function(date) {
    //   return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    // },
    localDate: function (date) {
      return moment.utc(date).local().format("MMM Do YYYY - h:mm a");
    },
  },

  methods: {
    changePage() {
      console.log(this.page);
      this.pagination.pageNumber = this.page;
      this.getDataFromApi();
    },

    changePageArchive() {
      console.log(this.pageArchive);
      this.paginationArchive.pageNumber = this.pageArchive;
      this.getTrashedFromApi();
    },

    setDefault() {
      this.billPayment = Object.assign({}, this.billPaymentDefault);
    },
    onProductSelect() {
      let product = this.billProducts.find(
        (x) => x.billProductId === this.billPayment.billProductId
      );
      this.billPayment.quantity = product.unitQty
        ? product.quantity / product.unitQty
        : product.quantity;
      this.billPayment.refundAmount = product.amount;
    },
    deleteInvoice(item) {
      this.editedItemIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },
    trashItem() {
      console.log(this.editedItem);
      var id = this.editedItem.productReturnId;
      this.isLoading = true;
      this.$http

        .get(`/ProductReturns/${id}/Archive`)
        .then((response) => {
          if (response.status == 200) {
            this.$toast.success("Deleted Succefully", "Success", {
              position: "topRight",
            });
            this.isLoading = false;
            this.dialogDelete = false;
            this.getDataFromApi();
            // this.items.splice(this.editedItemIndex, 1);
            // this.archiveItems.unshift(this.editedItem);
          } else {
            this.isLoading = false;
            this.$toast.success(response.data.message, "Error", {
              position: "topRight",
            });
          }

          // this.getDataFromApi();
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    restoreItem(item) {
      this.editedIndexFiltered = this.archiveItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedIndexFiltered = this.archiveItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },

    restoreItemConfm() {
      var id = this.editedItem.productReturnId;
      this.isLoading = true;
      this.$http

        .get(`/ProductReturns/${id}/Restore`)

        .then((response) => {
          if (response.data.success === true) {
            this.getDataFromApi();
            this.getTrashedFromApi();
            // this.archiveItems.splice(this.editedIndexFiltered, 1);

            // this.items.unshift(response.data.data);

            this.isLoading = false;
            // this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.editedItem.productReturnId;
      this.$http
        .delete("/ProductReturns/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            // this.getTrashedFromApi();
            this.archiveItems.splice(this.editedIndexFiltered, 1);
            this.isLoading = false;
            this.$toast.success("Deleted Permanently.", "Success", {
              position: "topRight",
            });
          }
        })

        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.$toast.error("Error", "Error", {
            position: "topRight",
          });
          // if (error.status === 500) {

          //   this.$toast.error("500 ERROR", "Error", {
          //     position: "topRight"
          //   });
          // }
          // if (error.response.status === 404) {

          //   this.$toast.error(response.data.message, "Error", {
          //     position: "topRight"
          //   });
          // }
        });
      this.dialogPermanentDelete = false;
    },

    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getDataFromApi() {
      this.isLoading = true;
      this.$http
        .post("/ProductReturns/Paginated", this.pagination)
        .then((response) => {
          this.itemsData = response.data.data;
          this.items = response.data.data.items;
          this.pageCount = this.itemsData.totalPages;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error) {
            this.isLoading = false;

            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },

    getBillProducts(invoice) {
      this.$http.get(`BillHeader/BillNo/${invoice}`).then((res) => {
        if (res.data.success) {
          this.billProducts = res.data.data.billProducts;
        } else {
          this.billProducts = [];
        }
      });
    },
    viewTrashed() {
      this.dialogTrash = true;
      this.getTrashedFromApi();
    },
    getTrashedFromApi() {
      this.isLoading = true;
      this.$http
        .post("/ProductReturns/Archived", this.paginationArchive)
        .then((response) => {
          this.archiveData = response.data.data;
          this.archiveItems = response.data.data.items;
          this.pageCountArchive = this.archiveData.totalPages;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },

    validate() {
      const pay = this.billPayment;
      let product = this.billProducts.find(
        (x) => x.billProductId === pay.billProductId
      );
      this.billPayment.quantity = product.unitQty
        ? this.billPayment.quantity * product.unitQty
        : this.billPayment.quantity;

      if (pay.refundAmount != "" && pay.refundAmount != 0 && pay.reason != "") {
        console.log("Bill Payment", this.billPayment);
        this.billPayment.refundAmount = parseFloat(
          this.billPayment.refundAmount
        );
        this.save();
      } else {
        console.log("Bill Payment", this.billPayment);
        if (pay.refundAmount == 0 || pay.refundAmount == "") {
          this.$toast.error("Enter the valid amount", "Error", {
            position: "topRight",
          });
        }
        if (pay.reason == "") {
          this.$toast.error("Reason Required", "Error", {
            position: "topRight",
          });
        }
      }
    },

    formatDate(date) {
      var dateTime = new Date();
      return (
        date +
        "T" +
        [
          this.padTo2Digits(dateTime.getUTCHours()),
          this.padTo2Digits(dateTime.getUTCMinutes()),
          this.padTo2Digits(dateTime.getUTCSeconds()),
        ].join(":")
      );
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    save() {
      console.log(this.billPayment);
      this.isLoading = true;
      this.$http
        .post(`ProductReturns`, this.billPayment)
        .then((response) => {
          if (response.data.success === true) {
            //this.items.unshift(response.data.data);
            this.getDataFromApi();
            console.log("Payment", response);
            this.paymentView = false;
            this.isLoading = false;
            this.$toast.success("Added successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error("Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },
  },
};
</script>
<style>
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
